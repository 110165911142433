













import { Vue, Component, Prop } from "vue-property-decorator";
import SectionTitle from "@/components/detail-page/SectionTitle.vue";
import Contact from "@/entity/Contact";
import ItemList from "@/components/detail-page/ItemList.vue";
import ContactCompanies from "@/components/detail-page/ContactCompanies.vue";

@Component({
  components: {
    SectionTitle,
    ItemList,
    ContactCompanies,
  },
})
export default class ContactNoteCard extends Vue {
  @Prop({ required: true }) contact!: Contact;

  get buyerType(): string {
    return this.contact.buyerType?.name ?? this.$tc("na", 1);
  }
}
