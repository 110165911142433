


















import { Component, Prop, Vue } from "vue-property-decorator";
import ContactContactCard from "@/components/contact-detail/ContactContactCard.vue";
import ContactNoteCard from "@/components/contact-detail/ContactNoteCard.vue";
import DetailLayoutWrapper from "@/components/detail-page/DetailLayoutWrapper.vue";
import ContactAttachment from "@/components/contact-attachment/ContactAttachment.vue";
import Contact from "@/entity/Contact";

@Component({
  components: {
    ContactContactCard,
    DetailLayoutWrapper,
    ContactNoteCard,
    ContactAttachment,
  },
})
export default class ContactDetailView extends Vue {
  @Prop({ required: true }) contact!: Contact;
}
