


































import { Vue, Component, Prop } from "vue-property-decorator";
import SectionTitle from "@/components/detail-page/SectionTitle.vue";
import SectionHeading from "@/components/detail-page/SectionHeading.vue";
import ContactList from "@/components/detail-page/ContactList.vue";
import Address from "@/components/detail-page/Address.vue";
import Contact from "@/entity/Contact";

@Component({
  components: {
    SectionTitle,
    SectionHeading,
    ContactList,
    Address,
  },
})
export default class ContactContactCard extends Vue {
  @Prop({ required: true }) contact!: Contact;

  get contactName(): string {
    let salutation = "";

    if (this.$te(`salutationNames.${this.contact.salutationForm}`)) {
      salutation = this.$tc(`salutationNames.${this.contact.salutationForm}`);
    }

    return `${salutation} ${this.contact.firstName} ${this.contact.lastName} ${
      this.contact.title || ""
    }`;
  }
}
